import * as React from 'react';
import { isLoggedIn } from '../../API/Http/Requests';
import { CartPackage } from '../../API/Interfaces/Bundle';
import FacebookAnalytics from '../../Util/analytics/FacebookAnalytics.web';
import CartProductCard from './Overview/NewCartProductCard';
import Storage from '../CrossPlatform/Storage/Storage';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import { EURO } from '../../Util/currency';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

export default class Overview extends React.Component<any> {
    storage = new Storage;

    componentDidMount() {
        this.props.store.setActiveStep('overview');
    }

    async nextPage() {
		let totalPrice = this.calculateTotalPrice();
        this.props.store.setOriginalPrice(totalPrice);
		this.props.store.setTotalPrice(totalPrice);

		let link: string = 'pickLogin';

		// this.checkIfMembership();

		this.props.navigation.navigate(link);
        FacebookAnalytics.getInstance().initiateCheckout(this.props.store.product.products); // Call pixel event InitiateCheckout with argument 'products'.
    }

    async checkIfLoggedIn() {
		return await isLoggedIn().catch(e => console.log(e));
	}

    calculateShippingCosts(): number {
		let price: number = 0;
		this.props.store.product.products.map(product => {
            if (product.flower.category === 'Plant' || product.flower.category === 'Overig') {
				price = 0;
			} else if (product.flower.packageIntname && product.flower.packageIntname.toLowerCase().indexOf('tulpen') > -1) {
				price = 4.99;
			} else if (this.props.store.app.config.variablePrices.deliveryCost) {
				price = this.props.store.app.config.variablePrices.deliveryCost;
			} else {
				price = 8.1;
			}
			price = price * product.flower.amount;
		});
		return price;
	}

	calculateFlowerPrice(): number {
		let price: number = 0;
		this.props.store.product.products.map( product => {
			if(product.flower.amount) {
				price += product.flower.price * product.flower.amount;
			} else {
				price += product.flower.price;
			}
		});
		return price;
	}

	calculateVasePrice(): number {
		let price: number = 0;
        this.props.store.product.products.map(product => {
			if(product.flower.vaas) {
                const staticVasePrice = this.props.store.app.config.variablePrices.vasePrice;
                const vasePrice = staticVasePrice ? staticVasePrice : product.flower.vasePrice;
                price += vasePrice * product.flower.amount;
			}
		});
		return price;
    }

    calculateSpoedPrice(): number {
		let price: number = 0;
        this.props.store.product.products.map(product => {
            if (product.flower.delivery.sameDay) {
                price += 15 * product.flower.amount;
            }
		});
		return price;
    }

    calculateCardPrice(): number {
        let price: number = 0;
        this.props.store.product.products.map(product => {
			if (product.flower.note && product.flower.wishcardCode && product.flower.wishcardCode != '' && product.flower.wishcardCode !== 'GC000') {
                if (routingName === "amsterdam") {
                    price += product.flower.wishcardCode !== 'GC001' ? 
					    2.5 * product.flower.amount : 
                        0;
                } else {
                    price += product.flower.wishcardCode !== 'GC001' ? 
					    2.5 * product.flower.amount :
                        1.5 * product.flower.amount;
                }
			}
		});
		return price;
	}
    
    calculateChocolonelyPrice(): number {
        let price: number = 0;
        this.props.store.product.products.map(product => {
			if (product.flower.chocolonelyCode && product.flower.chocolonelyCode != '') {
                price += 4 * product.flower.amount;
			}
		});
		return price;
	}

	/**
	 * This function calculates the total price of the order.
	 * The input is per flower type.
	 * @param  flowerPrice price of the total amount of a certain flower.
	 * @param  vasePrice   Price of a vase.
     * etc.
	 * @return             The totalprice of all flowers + the price of the vases etc.
	 */
	calculateTotalPrice(): number {
        const flowerPrice = this.calculateFlowerPrice();
        const vasePrice = this.calculateVasePrice();
        const shippingCosts = this.calculateShippingCosts();
        const cardCost = this.calculateCardPrice();
        const spoedPrice = this.calculateSpoedPrice();
        const chocolonelyPrice = this.calculateChocolonelyPrice();
        
		if(flowerPrice < 0 || vasePrice < 0) {
			return 0;
		}
		let price : number = 0;
		price = flowerPrice + vasePrice + shippingCosts + cardCost + spoedPrice + chocolonelyPrice;
		return price;
    }

    async updateProduct(prod: CartPackage) {
        let products : any[] = await this.storage.retrieveData('products');
        const tmpFlower = Object.assign({}, prod);
        products.map( product => {
            if(product.flower.id === prod.id) {
                product.flower = tmpFlower;
            }
        });

        this.setState({flower: tmpFlower});
        this.storage.storeData('products', products);
        this.props.store.setProducts(products);
    }

    render() {
        const mobileView = this.props.mobileView;
        const textSize = mobileView ? 15 : 18;
        const shippingCosts = this.calculateShippingCosts();
        if (this.props.store.product.products.length === 0) {
            return (
                <div style={{display: 'flex', flexDirection: 'column', width: '90%', paddingLeft: '5%'}}>
                    <MakeText style={{fontSize: mobileView ? 24 : 36, marginTop: 50}} fontFamily={'Arno Pro'}>Je hebt nog geen bloemen in je mand</MakeText>
                    <Button style={{marginTop: 50}} textStyle={{fontSize: textSize, width: '100%', height: mobileView ? 40 : 50, fontFamily: 'Open Sans'}} text={'Begin hier met shoppen'} color={'greenWhite'} onPress={() => this.props.mainNavigation.navigate('webshop')} />
                </div>
            );
        }

        return (
            <div className='col' style={{justifyContent: 'space-around', width: mobileView ? '100%' : '60%', marginLeft: mobileView ? '0%' : '20%'}}>
                <div className='col' style={{maxHeight: mobileView ? undefined : 600, width: mobileView ? '95%' : 750, marginLeft: mobileView ? '2.5%' : undefined, overflowY: mobileView ? undefined : 'auto', overflowX: mobileView ? undefined : 'hidden', alignItems: 'center', marginTop: mobileView ? 15 : undefined}}>
                    {this.props.store.product.products.map((p, i) => <CartProductCard key={'kaartjuh-' + p.id + '-' + i} staticVasePrice={this.props.store.app.config.variablePrices.vasePrice} cardPrice={this.calculateCardPrice()} chocolonelyPrice={this.calculateChocolonelyPrice()} package={p.flower} updateProduct={(prod: CartPackage) => this.updateProduct(prod)} removeProduct={this.props.store.removeProduct.bind(this)} mobileView={mobileView} />)}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '90%', padding: mobileView ? '5%' : undefined, marginTop: 10, alignSelf: 'center'}}>	
                    <div style={{display: 'flex', flexDirection: 'column', height: mobileView ? undefined : 150, backgroundColor: 'rgba(196,196,196,0.2)', padding: 25}}>
                        {shippingCosts > 0 &&
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
                                <MakeText fontFamily={'Open Sans'} style={{fontSize: textSize}}>Bezorgkosten:</MakeText>
                                <MakeText fontFamily={'Open Sans'} style={{fontSize: textSize, fontWeight: 'bold'}}>{EURO(shippingCosts)}</MakeText>
                            </div>
                        }
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <MakeText fontFamily={'Open Sans'} style={{fontSize: textSize}}>Totaal{ process.env.REACT_APP_NODE_ENV === "portal" && ' inc. BTW'}:</MakeText>
                            <MakeText fontFamily={'Open Sans'} style={{fontSize: textSize, fontWeight: 'bold'}}>{EURO(this.calculateTotalPrice())}</MakeText>
                        </div>
                        { process.env.REACT_APP_NODE_ENV === "portal" &&
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <MakeText fontFamily={'Open Sans'} style={{fontSize: textSize}}>Totaal ex. BTW:</MakeText>
                                <MakeText fontFamily={'Open Sans'} style={{fontSize: textSize, fontWeight: 'bold'}}>{EURO(this.calculateTotalPrice() / 1.09)}</MakeText>
                            </div>
                        }
                        <Button testID='nextPage' style={{marginTop: 25}} textStyle={{fontSize: textSize, width: '100%', height: mobileView ? 40 : 50, fontFamily: 'Open Sans'}} text={'Verder naar bestellen'} color={'greenWhite'} onPress={() => this.nextPage()} />

                    </div>
                </div>
                {mobileView &&
                    <a className='mediumText' style={{alignSelf: 'center', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => this.props.navigation.navigate('webshop')}>Terug naar de webshop</a>
                }
            </div>
        );
    }
};
