import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import FAQItem from './FAQItem';
import MakeText from '../Common/MakeText/MakeText';
import { phone } from '../../Util/screen/HandleLayout';

export default (props: any) => {

	const mobileView: boolean = phone(props.width);

    const styles = StyleSheet.create({
		main: {
			maxWidth: props.width,
			marginBottom: 47,
		},
        imgRow: {
            flexDirection: props.width < 880 ? 'column' : 'row',
            justifyContent: props.width < 880 ? 'flex-start' : 'center',
            alignItems: props.width < 880 ? 'center' : 'flex-start'
        },
        imgContainer: {
			marginTop: 54,
			marginLeft: mobileView ? 0 : 71,
            width: props.width < 880 ? props.width * 0.8 : props.width * 0.3
        },
        sizeText: {
            margin: 5
        },
        faqListHeader: {
            fontSize: mobileView ? 32 : 36,
            lineHeight: 42,
            letterSpacing: 2.2,
            color: '#414141',
            marginBottom: 5
        }
    });

    return (

	    <View style={styles.main}>
            <MakeText fontFamily="Crimson Text" style={{fontSize: 56, alignSelf: 'center', letterSpacing: 2.2, color: '#414141', textAlign: 'center', marginTop: 50, marginBottom: mobileView ? 20 :50}}>F.A.Q.</MakeText>
            <View style={styles.imgRow}>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Bestellen</MakeText>
                    <FAQItem mobileView={mobileView} title='Hoe kan ik bloemen bestellen?' body='Dat is heel eenvoudig via onze webshop. Ga naar de webshop, kies wat moois uit en volg de 4 stappen in het bestelproces. Dan komen wij je binnenkort die mooie bloemen bezorgen.'/>
                    <FAQItem mobileView={mobileView} title='Kan ik ook eenmalig bloemen bestellen?' body='Ja, dat kan ook. Dit doe je door een bos bloemen te selecteren en vervolgens op ‘bestel direct’ te klikken. Op deze manier abonneer je je niet op het Family Membership, maar worden de bloemen eenmalig bezorgd.'/>
                    <FAQItem mobileView={mobileView} title='Krijg ik ook een bevestiging van mijn bestelling?' body='Ja, je ontvangt een mailtje met daarin de bezorggegevens. Die bevestigingsmail ontvang je op het e-mailadres dat je hebt opgegeven. Let op: het is mogelijk dat dit bericht in je spambox terecht komt.'/>
                    <FAQItem mobileView={mobileView} title='Hoe werkt het Family Membership?' body='Vooraf: het Family Membership is volledig flexibel. Je kunt opzeggen wanneer je wilt. Als family member kun je kiezen tussen onze normale boeketten. Daarnaast zijn er ook fruitdozen voor op het werk te bestellen die alleen maandag worden bezorgd. Heb je je keuze gemaakt, dan bepaal je vervolgens jouw bezorgdag; de interval (wekelijks, twee-wekelijks, maandelijks of eenmalig), en als laatste je bezorgmoment in de loop van de dag. In jouw account kan je vervolgens onder ‘Mijn memberships’ de gegevens van je membership wijzigen. Je kunt dan bijvoorbeeld ook annuleren of een pauze inlassen.'/>
                </View>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text"style={styles.faqListHeader}>Betalingen</MakeText>
                    <FAQItem mobileView={mobileView} title='Hoe kan ik betalen en wanneer vindt de betaling plaats?' body='Nadat jij je eerste bloemen hebt besteld, vindt de betaling direct plaats via de betalingsmethode die jij hebt gekozen. Als je een membership hebt afgesloten, versturen we een factuur om de twee weken.' />
                    <FAQItem mobileView={mobileView} title='Zijn er nog extra kosten aan verbonden, zoals bezorgkosten of membership kosten?' body='De prijzen in de webshop zijn inclusief BTW. De prijzen van de bloemen zijn exclusief € 8.10 (of € 4.99 voor brievenbuspakketten/-bloemen) bezorgkosten. De prijzen van de planten en fruitdozen (niet fruitmanden!) zijn inclusief bezorgkosten. Fruitmanden zijn exclusief bezorg-/handlingkosten. Aan een membership zijn geen kosten verbonden, behalve als er vaasservice is (10 euro).' />
                    <FAQItem mobileView={mobileView} title='Kan ik mijn bestelling annuleren en krijg ik dan ook mijn geld terug?' body='Als je de annulering doorgeeft tenminste 24 uur voor de geplande bezorging, zullen wij er voor zorgen dat je geen bloemen krijgt en zal het geld worden teruggestort op jouw rekening.' />
                </View>
            </View>
            <View style={styles.imgRow}>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Bestellingen</MakeText>
                    <FAQItem mobileView={mobileView} title='Kan ik zelf nog inzien wat ik heb besteld?' body='Dat kan zeker. Ga in jouw account naar ‘ordergeschiedenis’.' />
                    <FAQItem mobileView={mobileView} title='Kan ik mijn Memberhip bloemen (eenmalig) op een ander adres laten bezorgen?' body='Ja, stuur dan even een mailtje minimaal een dag van te voren naar: info@theflowerfamily.nl met daarin het juiste adres.' />
                    <FAQItem mobileView={mobileView} title='Zijn orderwijzigingen mogelijk?' body='Ja, stuur dan even z.s.m. een mailtje naar: info@theflowerfamily.nl. Wij zullen altijd ons best doen de wijzigingen voor elkaar te krijgen.' />
                </View>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Bezorging</MakeText>
                    <FAQItem mobileView={mobileView} title='Waar en wanneer bezorgt The Flower Family?' body='Wij regelen bezorging voor heel Nederland. Boeketten zijn ook mogelijk in andere landen (op aanvraag via info@theflowerfamily.nl). Fruit en planten bezorgen wij alleen in Nederland en België. Fruitboompjes en brievenbusbloemen alleen in Nederland. De bezorgdagen/-vensters hangen af van het product.' />
                    <FAQItem mobileView={mobileView} title='Kan ik aangeven hoe laat ik mijn bloemen wil ontvangen?' body="Ja, tijdens het bestelproces is heel eenvoudig aan te geven of je de bloemen overdag (09.00 – 18.00 uur) of 's avonds bezorgd wilt krijgen (18.00 – 22.00 uur)" />
                    <FAQItem mobileView={mobileView} title='Wat gebeurt er met mijn bloemen als ik niet thuis ben?' body='De bloemen worden bezorgd bij de buren en zullen dit via een kaartje in de brievenbus laten weten.' />
                </View>
            </View>
            <View style={styles.imgRow}>
                {/* <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Spaarprogramma TFF Exclusive</MakeText>
                    <FAQItem mobileView={mobileView} title='Wat is het spaarprogramma TFF Exclusive?' body='We bouwen graag duurzame relaties op met onze Family members. Als member ontvang je daarom bij iedere bestelling een x aantal punten die te verzilveren zijn voor mooie cadeaus. Hoe meer bloemen en planten jij bestelt, hoe meer punten je ontvangt.' />
                    <FAQItem mobileView={mobileView} title='Hoe spaar ik punten?' body='Je spaart punten bij elke bestelling die bezorgd wordt. Dus wanneer je een twee-wekelijks membership hebt, ontvang je elke twee weken een bos bloemen én 20 punten.' />
                    <FAQItem mobileView={mobileView} title='Wat kan ik met mijn gespaarde punten?' body='Je tegoed kun je verzilveren voor mooie cadeaus' />
                    <FAQItem mobileView={mobileView} title='Hoe kan ik mijn punten verzilveren?' body='' />
                    <FAQItem mobileView={mobileView} title='Waar kan ik mijn puntentegoed inzien?' body='Op de TFF Exclusive/spaarprogramma pagina.' />
                    <FAQItem mobileView={mobileView} title='Is er een maximaal aantal punten wat ik tegelijk mag verzilveren en na hoelang komen mijn punten te vervallen?' body='Ja, per keer mag je maximaal 500 punten verzilveren en de punten die je spaart zijn 6 maanden geldig, dan komen ze te vervallen.' />
                </View> */}
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Betekenisvol ondernemen</MakeText>
                    <FAQItem mobileView={mobileView} title='Wat bedoelen jullie met betekenisvol ondernemen?' body={`In alles wat The Flower Family doet hebben wij oog voor onze medemens, het milieu en de maatschappij. Wij zijn ervan overtuigd dat ondernemen in de nabije toekomst alleen nog maatschappelijk geaccepteerd wordt als de onderneming effectief bijdraagt aan de bloei van mens, milieu en maatschappij.\r\nThe Flower Family doet dat op de volgende twee manieren:\r\n\r\nOnze bloemen zijn afkomstig van duurzame lokale bloemisten. Deze bloemisten kopen gecertificeerde bloemen in (MPS A/MPS A+, biologisch).\r\n\r\nDe uitvoering van ons bedrijf wordt voor het merendeel gedaan door mensen met een achterstand tot de arbeidsmarkt - onze ‘talenten’ genaamd. Deze talenten - denk aan mensen met lichamelijke of verstandelijke beperkingen, of vluchtelingen - worden bij ons opgeleid en kunnen zo weer opbloeien in de maatschappij.`} />
                    <FAQItem mobileView={mobileView} title='Wat houdt ‘duurzame bloemen’ in bij The Flower Family?' body='Al de bloemisten in ons netwerk, kopen een hoog percentage bloemen in die gecertificeerd zijn met tenminste een MPS-A certificering. Wij vragen hen die zoveel mogelijk te gebruiken voor onze boeketten. Het keurmerk geeft aan hoe de teler ten opzichte van andere bedrijven scoort op de aspecten mest, bestrijdingsmiddelen, water- en energieverbruik.' />
                </View>
            </View>
            <View style={styles.imgRow}>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Overig</MakeText>
                    <FAQItem mobileView={mobileView} title='Hoe kan ik contact met jullie opnemen?' body={`Bellen: +31 20 245 5887\r\n\r\nMailen naar: info@theflowerfamily.nl\r\n\r\nFacturen: facturen@theflowerfamily.nl`} />
                    <FAQItem mobileView={mobileView} title="Zijn mijn gegevens veilig bij jullie?" body='Vanzelfsprekend! Je kunt er altijd van uitgaan dat we jouw gegevens – zoals je adres, e-mailadres en telefoonnummer – uitsluitend gebruiken voor onze eigen dienstverlening. Met die gegevens houden we je bijvoorbeeld op de hoogte van jouw aanmelding of de bezorging. We zullen je gegevens nooit verstrekken aan derde partijen en gaan vertrouwelijk om met je gegevens in e-mails en andere berichten aan jou.' />
                    <FAQItem mobileView={mobileView} title="Ik heb een suggestie/feedback om jullie bloemenservice te verbeteren, hoe geef ik dit door?" body='Wat fijn dat je suggesties voor ons hebt, we zijn erg blij met elk ideeom onze service te verbeteren. Stuur ons een mailtje naar info@theflowerfamily.nl. Alvast super bedankt!' />
                </View>
            </View>
	    </View>
    );
}
