import { ILocation } from "../../API/IDataService";
import axios from 'axios';

export default async (location: ILocation) => {
    // feature depends on the API from https://www.api-postcode.nl/
    const axiosInstance = axios.create({ baseURL: '', timeout: 20000, responseType: 'json' });
    // create an array for filtered addresses result
    let addressLookedUp:[{ straatnaam:string|undefined, woonplaatsnaam:string|undefined, housenumbers:string[], additions:string[] }] = [{ // the object array that gets returned at the end
        straatnaam: undefined,
        woonplaatsnaam: undefined,
        housenumbers: [''],
        additions: [''],
    }];
    if (location.postalcode) { // we need a postalcode to fetch addresses
        // get (Dutch) addresses from server (axios request)
        const addresses = await axiosInstance.get(`https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?rows=100&fl=straatnaam huisnummer huisletter huisnummertoevoeging huis_nlt postcode woonplaatsnaam&q=postcode%3A` +
            `${location.postalcode.replace(/\s/g,'').toUpperCase()}`)
            .then(res => {
                if (res.data.response) {
                    console.log('res.data.response', res.data.response);
                    return res.data.response.docs.filter(doc => doc.huisnummer);
                }
            })
            .catch(() => undefined)
        // temporarily store 'housenumbers' and 'additions', but not yet in the 'addressLookedUp' array
        let housenumbers: string[] = [];
        let additions: string[] = [];
        // filter addresses by postalcode
        if (addresses && addresses.length > 0) {			
            addressLookedUp = addresses.filter((addressData) => {
                if (addressData.huisnummer) {
                    if (addressData.huisnummer && addressData.huisnummer.toString().trim().length > 0) { // when the fetched data contains a housenumber
                        if (housenumbers.indexOf(addressData.huisnummer.toString().trim()) === -1) { // when the housenumber is not in 'housenumbers' yet, push it to this array
                            housenumbers = housenumbers[0] === '' ? [] : housenumbers;
                            housenumbers.push(addressData.huisnummer.toString().trim());
                        }
                        // when the input (= 'location') contains a housenumber, push the housenumber-additions belonging to it to the 'additions' array,
                        if (location.housenumber && location.housenumber.toString().trim() === addressData.huisnummer.toString().trim()) {
                            additions = additions[0] === '' ? [] : additions;
                            // convert 'additions'
                            if (addressData.huisnummertoevoeging) {
                                if (additions.indexOf(addressData.huisnummertoevoeging.toString().trim()) === -1) {
                                    addressData.huisnummertoevoeging.trim().toLowerCase() === 'o' ?
                                    additions.push('0') :
                                    additions.push(addressData.huisnummertoevoeging.toString().trim());
                                }
                            } else if (addressData.huisletter) {
                                if (additions.indexOf(addressData.huisletter.trim()) === -1) {
                                    addressData.huisletter.trim().toLowerCase() === 'o' ?
                                    additions.push('0') :
                                    additions.push(addressData.huisletter.trim());
                                }
                            }
                        }
                        return true; // return the filtered addresses in the array 'addressLookedUp'
                    }
                    return false; // leave the array 'addressLookedUp' empty (return no addresses)
                }
            });
            // create in the first element of the addressData array, empty arrays for storing housenumbers and additions
            addressLookedUp[0].housenumbers = [];
            addressLookedUp[0].additions = [];
        }
        // do not assume the 1st element in addressLookedUp always delivers the right streetname
        // we hack the streetname for addressLookedUp[0].straatnaam, by replacing it after matching the input (location.housenumber) with a fetched address housenumber (addressData.huisnummer)
        addresses.map(addressData => {
            if (addressData.huisnummer) {
                if (location.housenumber && addressData.huisnummer && (location.housenumber.toString().trim() === addressData.huisnummer.toString().trim())) {
                    addressLookedUp[0].straatnaam = addressData.straatnaam;
                }
            }
        });
        // fill addressLookedUp.housenumbers with the elements from 'housenumbers', after sorting
        if (housenumbers && housenumbers[0]) {
            // sort housenumbers (first numeric then non-numeric)
            housenumbers.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
            // push to housenumbers array
            housenumbers.map(housenumber => {
                if (addressLookedUp[0].housenumbers) {
                    addressLookedUp[0].housenumbers.push(housenumber);
                }
            });
        }
        // fill addressLookedUp.additions with the elements from 'additions', after sorting and converting
        if (additions && additions[0]) {
            // sort additions (first numeric then non-numeric)
            additions.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
            // split numeric and non-numeric elements
            let additionsNonNumeric: string[] = []; // for storing non-numeric
            additions.map(addition => {
                if (addressLookedUp[0].additions) {
                    if (typeof Number(addition) === 'number' && isFinite(Number(addition))) {
                        addressLookedUp[0].additions.push(addition); // storing numeric
                    } else {
                        additionsNonNumeric.push(addition); // storing non-numeric
                    }
                }
            });
            // add non-numeric additions to array of stored numeric additions, based on common sense
            additionsNonNumeric.map(nonNumAdd => {
                if (nonNumAdd.toLowerCase() === 'h' || nonNumAdd.toLowerCase() === 'hs' || nonNumAdd.toLowerCase() === 'huis') {
                    if (Number(addressLookedUp[0].additions[0]) === 0) {        // when 1st element (= addition) is '0' ('basement level')
                        addressLookedUp[0].additions.splice(1, 0, nonNumAdd);
                    } else if (
                        Number(addressLookedUp[0].additions[0]) === 1 ||        // when 1st element (= addition) is '1' ('first floor' and not 'ground level')
                        addressLookedUp[0].additions.indexOf('0') < 0           // when 1st element (= addition) < 0 (below normal 'house entrancelevel')
                    ) {
                        addressLookedUp[0].additions.splice(addressLookedUp[0].additions.indexOf('0'), 0, nonNumAdd);
                    }
                } else {
                    addressLookedUp[0].additions.push(nonNumAdd);
                }
            });
        }

        // Return
        return addressLookedUp[0] && addressLookedUp[0].housenumbers && addressLookedUp[0].housenumbers.length > 0 ?
        {
            street: addressLookedUp[0].straatnaam ? addressLookedUp[0].straatnaam  : '',
            city: addressLookedUp[0].woonplaatsnaam ? addressLookedUp[0].woonplaatsnaam : '',
            housenumbers: addressLookedUp[0].housenumbers,
            additions: addressLookedUp[0].additions ? addressLookedUp[0].additions : [''],
        } :
        addressLookedUp[0];
    }
}