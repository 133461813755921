import * as React from 'react';
import { Bundle, Package } from '../../../API/Interfaces/Bundle';
import { EURO } from '../../../Util/currency';
import MakeText from '../../Common/MakeText/MakeText';
import Button from '../../CrossPlatform/Button/Button';
import './ProductCard.css';
// import configDefault from '../../../config/config.selector';
import { routingName } from '../../CrossPlatform/OrderProcess/NewOrderProcess.web';

// const conf = configDefault as any;

const findTitle = (flower: Package) => {
    let locale = flower.locale.find(l => l.locale === 'nl-NL' && l.key === 'title');
    if (locale && locale.value) {
        return locale.value;
    }
    return flower.category;
}

const getUrl = (partial: string) => {
    if (/^(https)/g.test(partial)) {
        return partial;
    } else {
        return 'https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/' + partial;
    }
}

export default (props: {bundle: Bundle, flower: Package, navigation: any, mobileView: boolean}) => {
    const mobileView = props.mobileView;
    const flower = props.flower;
    const img = getUrl(flower.image);

    const style = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: mobileView ? 279 : 279,
            margin: 15
        } as React.CSSProperties,
        imgStyle: {
            height: mobileView ? 279 : 279,
            width: mobileView ? 279 : 279,
            objectFit: 'cover'
        } as React.CSSProperties,
        title: {
            marginTop: 15,
            color: '#414141',
            fontSize: mobileView ? 24 : 36,
            textAlign: 'center',
            width: 320,
        } as React.CSSProperties,
        price: {
            marginTop: 15,
            color: '#414141',
            fontSize: mobileView ? 15 : 24,
        } as React.CSSProperties,
    };

    return (
        <div className='productCardContainer' style={style.container} onClick={() => props.navigation.navigate('Product details', {bId: props.bundle.id, pId: flower.id})}>
            <div style={{position: 'relative'}}>
                <img src={img} style={style.imgStyle} />
                { routingName !== 'amsterdam' && 
                    (props.bundle.category.substring(0, 13) === 'E-Flora-Plant' && (props.bundle.packages[0].packageIntname || '').toLowerCase().indexOf('tulp') === -1) ?
                    <div style={{position: 'absolute', top: 5, left: 5}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src='/images/delivery.svg' width={25} height={25}/>
                            <MakeText style={{fontSize: 16, marginLeft: 5, letterSpacing: 1}}>Bezorging kan eventueel 3-5 werkdagen duren!</MakeText>
                        </div>
                        {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src='/images/delivery.svg' width={25} height={25}/>
                            <MakeText style={{fontSize: 16, marginLeft: 5, letterSpacing: 1}}>Gratis bezorging!</MakeText>
                        </div> */}
                    </div>
                : undefined}
                { props.bundle.category.substring(0, 13) === 'E-Flora-Plant' && props.bundle.packages[0].packageIntname.toLowerCase().indexOf('tulp') !== -1 &&
                    <div style={{position: 'absolute', top: 5, left: 5}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <img src='/images/delivery.svg' width={25} height={25}/>
                        <MakeText style={{fontSize: 16, marginLeft: 5, letterSpacing: 1}}>Bezorging alleen vanaf de volgende dag, di-za en van 9-17u!</MakeText>
                    </div>
                </div>
                }
            </div>
            <MakeText style={style.title} fontFamily='Arno Pro'>
                {findTitle(flower)}
            </MakeText>
            <MakeText style={style.price} fontFamily='Open Sans'>
                {props.bundle.isFlowers ? `vanaf ${EURO(19.95)}` : EURO(flower.price)}
            </MakeText>
            <Button testID='orderPlant' style={{alignSelf: 'center', marginTop: 15, width: '100%'}} textStyle={{fontSize: 18, width: '100%', height: mobileView ? 40 : 70}} text={'Bestel nu!'} color={'lightGreen'} onPress={() => props.navigation.navigate('Product details noquery', {pId: flower.id, intname: props.bundle.intname.replace(/ /g, '_'), size: flower.size})} />
        </div>
    );
}
