import * as React from 'react';
import { View, ActivityIndicator } from 'react-native';
import {getOrdersForUser} from "../../API/Http/Requests";

import AccountItemHeader from './AccountItemHeader';
import MakeText from '../Common/MakeText/MakeText';
import styles from './AccountStyles';
import Button from '../CrossPlatform/Button/Button';
import moment from 'moment';
import statusToMessage from '../../Util/statusToMessage';
import { Package } from '../../API/Interfaces/Bundle';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

const findTitle = (flower: Package) => {
    let locale = flower.locale.find(l => l.locale === 'nl-NL' && l.key === 'title');
    if (locale && locale.value) {
        return locale.value;
    }
    return flower.category;
}

export default class OrderData extends React.Component<any> {

	state = {
		user: undefined as any,
		orders: undefined as any,
	}

	componentDidMount() {
		if (this.props.orders) {
			this.setState({orders: this.props.orders.reverse()});
		} else {
			getOrdersForUser().then( user => {
				this.setState({user, orders: user.orders.reverse()});
			});
		}
	}

	senderForDelivery(tav, firstname, lastname) {
		let sender = '';
		// if (email /* && portalDomains.includes('@' + email.split('@')[1]) */) { // for Portals
		if (tav && tav.trim().length > 0) {
		  if (tav.includes('|') && tav.split(/\|/g)[1] && tav.split(/\|/g)[1].trim().length > 0) { // sender from tav field when a second element (= besteld door) exists in it 
			sender += tav.split(/\|/g)[1].trim() ? tav.split(/\|/g)[1].trim() : '';
		  }
		} else if ((sender && sender.trim().length < 1) || !sender) {
		  if (firstname) {
			sender += firstname;
		  }
		  if (lastname) {
			sender += ' ' + lastname;
		  }          
		}
		return sender.replace(/\&/g, 'en');
	}

	receiverForDelivery(PO, tav, firstname, fname, lastname, lname) {
		let receiver = '';
		if (fname && lname && lastname && firstname && (fname.trim().indexOf(firstname.trim()) > -1 && lname.trim().indexOf(lastname.trim()) > -1) ||
		  `${firstname.trim()} ${lastname.trim()}` === `${fname.trim()} ${lname.trim()}`
		) {
		  fname = fname.trim();
		  lname = lname.trim();
		} else if (fname.trim().indexOf(firstname.trim()) > -1) {
		  fname = '';
		}
		if ((PO && PO.includes('|') && PO.split(/\|/g)[2] && PO.split(/\|/g)[2].trim().length > 2) ||
		  (tav && tav.trim().length > 3 && tav.includes('|') && tav.split(/\|/g)[0] && tav.split(/\|/g)[0].trim().length > 0)
		) {
		  if (PO && PO.includes('|') && PO.split(/\|/g)[2] && PO.split(/\|/g)[2].trim().length > 2) { // for manual orders by TFF Support with receiver in PO
			receiver += PO.split(/\|/g)[2] ? PO.split(/\|/g)[2].trim() : '';
		  } else if (tav && tav.trim().length > 3 && tav.includes('|') && tav.split(/\|/g)[0] && tav.split(/\|/g)[0].trim().length > 0) { // receiver from tav field, when a first element (= bestemd voor) exists in it 
			receiver += tav.split(/\|/g)[0] ? tav.split(/\|/g)[0].trim() : '';          
		  } else { // for all other orders of clients that have a Portal
			receiver += lname && lname.trim().length > 0 ? lname.trim() : '';
		  }
		} else { // for orders not from clients who have a Portal
		  receiver += fname && fname.trim().length > 0 ? fname.trim() + ' ' : '';
		  receiver += lname && lname.trim().length > 0 ? lname.trim() : '';
		}
		if (receiver.trim().length === 0 || receiver.trim().indexOf(' ') === -1) {
		  receiver += ' -';
		}
		if (receiver.trim().length < 1) {
		  receiver = '- -';
		}
		return receiver.replace(/\&/g, ' en ');
	}

	render() {

        if (!this.state.orders) {
            return (
                <View style={{ flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                    <View style={{ marginTop: 20, marginBottom: 20 }}>
                        <ActivityIndicator size="large" color="#B9DCBF" />
                    </View>
                </View>
            );
        }

		if(!this.state.orders.length) {
			return (
				<View style={{ flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                    <View style={{ marginTop: 20, marginBottom: 20, alignItems: 'center', justifyContent: 'center' }}>
						<MakeText style={{marginBottom: 10}} fontFamily="Open Sans Italic">Je hebt nog geen bestelling geplaatst.</MakeText>
						<Button text="Doe nu hier jouw eerste bestelling!" color="blackOpacity" route='/bestel' onPress={() => this.props.navigation.navigate('webshop')} />
                    </View>
                </View>
			);
		}

		return (
            <View>

				{this.state.orders.map( (order: any) => {
					let title = findTitle(order.package);
					const loc = order.delivery.location;
					const user = this.state.user;
					if (order.package.category !== 'Overig' && order.package.category !== 'Plant' && order.package.category !== 'E-Flora-Plant') {
						title += ` - ${order.package.size}${(order.vase === 'large' || order.vase === 'medium') ? ' + vaas' : ''}`;
					}
					if (order.package.category.substring(0, 13) === 'E-Flora-Plant' && order.package.packageIntname && order.package.packageIntname.split('|')[2]) {
						title = `${order.package.packageIntname.split('|')[2].trim()}`;
						title += `${(order.vaseAmount > 0) ? ' + vaas' : ''}`;
					}
					return (
						<View key={order.id} style={styles.adresBottomMargin}>
							<AccountItemHeader testID='orderData' title={title} onPress={() => this.props.navigation.navigate('Order Details', {id: order.id})} message={routingName === 'amsterdam' ? 'Details' : undefined} />

							{ routingName === 'amsterdam' &&
								<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Ordernummer: {order.id}</MakeText>
							}
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Besteld: {moment(order.createdAt).format('D MMM HH:mm')}, Bezorging: {moment(order.delivery.windowStart).format('ddd D MMM YYYY')}</MakeText>
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Besteld door: {this.senderForDelivery(order.delivery.tav ? order.delivery.tav : '', user.firstName ? user.firstName : '', user.lastName ? user.lastName : '')}</MakeText>
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Bestemd voor: {this.receiverForDelivery('', order.delivery.tav ? order.delivery.tav : '', user.firstName ? user.firstName : '', loc.fname ? loc.fname : '', user.lastName ? user.lastName : '', loc.lname ? loc.lname : '')}</MakeText>
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Bez.adres: {`${loc.street} ${loc.housenumber}${loc.addition && loc.addition.trim().length > 0 ? ' ' + loc.addition : ''}, ${loc.postalcode} ${loc.city}`}</MakeText>
							{ order.status !== 'delivery' ?
								<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Status: {statusToMessage(order.status)}</MakeText>
								: <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Status: {statusToMessage(order.delivery.status)}</MakeText>
							}
						</View>
					);
				})}
			</View>
		)
	}
}
